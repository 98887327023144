<template>
  <ElDialog
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <template #title>
      <div class="custom-title">
        <div class="title-container">
          <span>{{ parameters.title }}</span>
          <ElBadge
            v-if="response"
            :class="['response-badge', successOrFailureBadge]"
          >
            {{ responseCode }}
          </ElBadge>
        </div>
        <AppButton
          icon="fa-light fa-xmark"
          size="text"
          @click="handleClose"
        />
      </div>
    </template>
    <ElForm
      v-if="parameters.inputs"
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <ElFormItem
        v-for="(input, index) in parameters.inputs"
        :key="input.parameterId + index"
        :prop="input.parameterId"
        class="form-item"
      >
        <div class="request-info">
          <div
            class="input-label"
            :class="{ 'with-desc': input.description }"
          >
            {{ input.label }}
          </div>
          <div v-if="input.description" class="input-description">
            {{ input.description }}
          </div>
        </div>
        <ElInput
          v-model="formData[input.parameterId]"
          :type="input.type"
          :placeholder="input.placeholder"
          :rows="3"
        />
      </ElFormItem>
    </ElForm>
    <div v-if="response" class="response-block">
      <AppButton
        size="small"
        type="secondary"
        text="COPY"
        class="copy-button"
        @click="copyResult"
      />
      <pre class="response-code">
        <code>{{ response }}</code>
      </pre>
    </div>
    <div v-if="errors.length">
      <ul>
        <li v-for="(error, index) in errors" :key="index" class="error-item">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="btn-group">
      <AppButton
        size="text"
        type="primary"
        :text="response ? 'Close' : 'Cancel'"
        @click="handleClose"
      />
      <AppButton
        type="primary"
        text="Submit"
        :is-disabled="isDisabled"
        @click="handleSubmit"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { makeRequest } from '@/services/supportService.js';

  /**
   * Handles the display and submission of a request form within a modal dialog.
   *
   * @vuedoc
   * @exports RequestModal
   * @category Components
   */
  export default {
    props: {
      /**
       * Controls the visibility of the modal.
       *
       * @type {boolean}
       */
      value: {
        type: Boolean,
        required: true,
      },
      /**
       * Values that represent endpoint information for each request.
       *
       * @type {object}
       * @property {string} title - title of request
       * @property {string} endpoint - endpoint string
       * @property {string} method - HTTP method type
       * @property {object} inputs - endpoint attributes
       */
      parameters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formData: {},
        rules: {},
        response: null,
        responseCode: null,
        errors: [],
      };
    },
    computed: {
      visible: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      /**
       * Validate that all required fields have input before allowing submission.
       *
       * @returns {boolean} - Returns true if all required fields are filled, otherwise false.
       */
      isDisabled() {
        if (this.parameters.inputs) {
          return this.parameters.inputs
            .filter((input) => input.required)
            .some((input) => !this.formData[input.parameterId] || this.formData[input.parameterId].trim() === '');
        }

        return false;
      },
      /**
       * Return a class based on the response code.
       *
       * @returns {string} - Returns success or failure style class
       */
      successOrFailureBadge() {
        if (this.responseCode === 200) {
          return 'success-badge';
        }

        return 'failure-badge';
      },
    },
    watch: {
      /**
       * When the visibility changes and we get new formData, create new rules for new form data.
       *
       * @param {boolean} value - The visibility value
       */
      visible(value) {
        if (value === true) {
          this.createRules();
        } else {
          this.rules = {};
        }
      },
    },
    methods: {
      /**
       * Dynamically generate validation rules for current form data.
       */
      createRules() {
        this.parameters.inputs.forEach((input) => {
          if (input.required) {
            this.rules[input.parameterId] = [
              {
                required: true,
                message: `${(input.label.replace('*', '')).trim()} is required`,
                trigger: 'blur',
              },
            ];
          }
        });
      },
      /**
       * Copy response to clipboard.
       *
       */
      copyResult() {
        navigator.clipboard.writeText(this.response);
      },
      /**
       * Close the request modal & clear it's fields.
       *
       */
      handleClose() {
        this.$refs.form.clearValidate();
        this.formData = {};
        this.response = null;
        this.rules = {};
        this.errors = [];
        this.$emit('input', false);
      },
      /**
       * Extract input data and convert to different data types if required
       *
       * @returns {object}
       */
      handleFormatInput() {
        const params = {};

        this.parameters.inputs.forEach((input) => {
          if (input.parameterId) {
            if (input.array && this.formData[input.parameterId]) {
              const formattedValue = this.formData[input.parameterId].split(',').map((item) => item.trim());

              params[input.parameterId] = formattedValue;
            } else {
              params[input.parameterId] = this.formData[input.parameterId];
            }
          }
        });

        return params;
      },
      /**
       * Look up record for provided input. Prevent submission & add to errors if not valid.
       *
       * @returns {object}
       */
      async handleRecordValidation() {
        const validationInput = this.parameters.inputs.find((input) => input.validation_string);

        if (validationInput) {
          const endpoint = validationInput.validation_string;
          const params = { [validationInput.parameterId]: this.formData[validationInput.parameterId] };
          const response = await makeRequest(endpoint, params, 'GET');

          if (response.status !== 200) {
            this.errors.push(`${validationInput.label.replace('*', '')}: ${this.formData[validationInput.parameterId]} is invalid.`);
          }
        }
      },
      /**
       * Make the request with params & display response data.
       *
       */
      async handleSubmit() {
        this.errors = [];
        const { endpoint, method } = this.parameters;
        const params = this.handleFormatInput();

        await this.handleRecordValidation();

        if (!this.errors.length) {
          try {
            const response = await makeRequest(endpoint, params, method);

            this.responseCode = response.status;
            if (response.status === 200) {
              this.response = JSON.stringify(response.data, null, 2);
              this.displayToast({
                message: 'Request success',
                type: 'success',
              });
            } else {
              this.response = response.data.message || 'An error occurred';
              this.displayToast({
                message: 'Request failed',
                type: 'error',
              });
            }
          } catch (error) {
            this.displayToast({
              message: 'Request failed',
              type: 'error',
            });
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
$margin-bottom: 10px;

.custom-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
  }
}

.form-item {
  margin-bottom: 36px;
}

.title-container {
  display: flex;
  align-items: center;
}

.el-dialog__headerbtn {
  margin-left: auto;
}

.request-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-label {
  font-weight: bold;
  margin-bottom: $margin-bottom;

  &.with-desc {
    margin-bottom: 0;
  }
}

::v-deep .el-dialog {
  padding: 20px;
  width: 50%;
}

.input-description {
  margin-bottom: 12px;
  margin-top: 6px;
  font-size: 12px;
  color: var(--tf-gray);
}

.response-block {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--tf-gray-light-medium);
  max-height: 250px;
  overflow: auto;
}

.response-code {
  margin: 0;
  white-space: pre-wrap;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding-bottom: 8px;
}

.copy-button {
  position: absolute;
  right: 14px;
  top: 14px;

  &:active {
    background-color: var(--tf-blue-light);
    transform: scale(.95);
  }
}

.error-item {
  color: var(--tf-red);
}

.btn-group {
  padding-top: 10px;
}

.response-badge {
  background-color: var(--tf-red);
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 0;
}

.success-badge {
  color: var(--tf-green);
  background-color: var(--tf-green-light);
}

.failure-badge {
  color: var(--tf-red);
  background-color: var(--tf-red-light);
}

</style>
